
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsCmsCat14 extends Vue {
  contentList: object[] = []; // cms内容列表
  currentPage: number = 1; // 当前页
  pageSize: number = 8; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数

  // 活动区当前页
  getCurrentPage () {
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.getSubCatContents();
  }
  // 获取文章
  getSubCatContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, Page: this.currentPage, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        this.contentList = result.Data;
        this.totalRecord = result.TotalRecord;
        console.log(result, ' 获取content列表');
      });
  }
  get pagerCount () {
    return this.isMobile ? 5 : 7;
  }
  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  handleCurrentChange (val) {
    this.currentPage = val;
    console.log(`当前页: ${val}`);
    this.getSubCatContents();
    // 返回顶部
    this.isMobile ? $(window).scrollTop(this.$refs['conbox']['offsetTop'] - 200) : $(window).scrollTop(this.$refs['conbox']['offsetTop'] - 300);
  }

  created () {
    this.getCurrentPage();
  }

  toUrl (n) {
    if (n.Url && n.IsOpenWindows) {
      window.open(n.Url);
    } else if (n.Url && !n.IsOpenWindows) {
      window.location.href = n.Url;
    } else {
      this.$router.push('/cms/content/' + n.Id);
    }
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    this.contentList = [];
    this.getSubCatContents();
  }
  @Watch('currentPage', { deep: true })
  onCurrentPageChange () {
    this.$router.push(`?page=${this.currentPage}`);
  }
}
